$u_blue: #0B3954;
$u_blue_dark: #05438A;
$u_blue_surface: #208dda;
$u_light_blue: #009DDD;
$u_light_blue_surface: #006386;
$u_gray: #EEF0F6;
$u_dark_gray: #707070;
$u_red: #CC3363;
$u_red_surface: #751b34;
$u_green: rgba(23, 184, 144, 1);
$u_green_surface: rgb(13, 96, 75);
$u_orange: #EE7B30;
$u_orange_surface: #93491e;
$side_width: 70px
