
nav {
  background-color: #fff;

  a, .a {
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: -.3em;
      bottom: -.3em;
      right: -.9em;
      left: -.9em;
      border: solid 2px #fff;
      border-radius: 100px;
      opacity: 0;
      transition: .3s ease-in-out;
    }

    &.selected:before, &:hover:before {
      opacity: 1;
    }

  }
}

.nav-filters-secondary {
  background: $u_light_blue;
  padding: 20px 0;

  .a {
    color: #fff
  }

  .a.active:before {
    opacity: 1;
  }

}

.nav-filters {
  color: #fff;
}

.filters-nav {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  width: $side_width;
  background: #fff;


  .filter-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}

//.filter-icon:hover ~ div {
//  opacity: 1;
//  pointer-events: all;
//}

.filter-list {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translateY(-50%);
  background: $u_blue_dark;
  color: #fff;
  padding: 25px 25px 25px 50px;
  z-index: 999;
  width: auto;
  white-space: nowrap;
  border-radius: 20px;
  opacity: 0;
  pointer-events: none;
  transition: .3s ease;

  .close-filters {
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 15px;
  }

  .filter {
    cursor: pointer;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.survey-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  a {
    img.filter-icon {
      position: relative !important;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      transform: translate(0);
    }

    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
