.donut-legend-item {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.donut-legend {
  position: absolute;
  left: 83%;
}

.donut-legend-item-outer {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin: 0;
  }
}

.donut-legend-item-title {
  font-size: .8rem;
  margin-left: 7px;
}
