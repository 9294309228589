@import "~bootstrap/scss/bootstrap";
@import "var";
@import "/public/dm/stylesheet";
@import "utils";
@import "progress";
@import "star";
@import "nav";
@import "pie";
@import "buttons";
@import "box";
@import "chip";
@import "donut";

body {
  background-color: $u_gray;
  padding-left: $side_width;
}

.remove-left-padding {
  margin-left: -$side_width;
}

.modebar-container {
  display: none !important;
}

.number {
  font-size: 9rem;
  font-weight: bold;
  color: $u_blue_dark
}

.title-valori {
  font-size: 2rem;
  font-weight: bold;
}

.number-circle {
  font-size: 1.5rem;
  border: solid 3px $u_blue_dark;
  color: $u_blue_dark;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  &.selected {
    background: $u_blue_dark;
    color: #ffff
  }
}

.pn-shadow {
  box-shadow: 0 -1px 20px 0 rgba(0, 0, 0, .16);
}






