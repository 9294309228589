@import "var";

.bg-blue {
  background-color: $u_blue;
}

.bg-light-blue {
  background-color: $u_light_blue;
}

.bg-gray {
  background-color: $u_gray;
}

.color-dark-gray {
  color: $u_dark_gray
}

.color-blue {
  color: $u_blue
}

.color-blue-dark {
  color: $u_blue_dark
}

.color-light-blue {
  color: $u_light_blue
}

.color-green {
  color: $u_green
}

.color-red {
  color: $u_red;
}

.color-orange {
  color: $u_orange;
}

.color-white {
  color: #fff !important;
}

input[type=text] {
  width: 100%;
  background: transparent;
  display: block;
  border: solid 2px $u_blue_dark;
  border-radius: 30px;
  padding: 5px 10px;
  color: #333333;
  font-size: .95rem;
}


@media all and (min-width: 1200px) {
  .h-xl-100 {
    height: 100% !important;
  }
}


@media all and (min-width: 992px) {
  .h-lg-100 {
    height: 100% !important;
  }
}
