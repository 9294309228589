

.u_progress {
  position: relative;
  height: 23px;
  border-radius: 30px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 1px #777;
    border-radius: 30px;
  }

  .progress-left {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .progress-left {
    left: 0;
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      top: -1px;
      bottom: -1px;
      left: 100%;
      background: #777;
    }
  }

  &.blue {
    .progress-left {
      background-color: $u_blue;
      border: solid 1px $u_blue;
    }

    .progress-number {
      color: $u_blue_surface;
    }
  }

  &.orange {
    .progress-left {
      background-color: $u_orange;
      border: solid 1px $u_orange;
    }

    .progress-number {
      color: $u_orange_surface;
    }
  }

  &.red {
    .progress-left {
      background-color: $u_red;
      border: solid 1px $u_red;
    }

    .progress-number {
      color: $u_red_surface;
    }
  }

  &.green {
    .progress-left {
      background-color: $u_green;
      border: solid 1px $u_green;
    }

    .progress-number {
      color: $u_green_surface;
    }
  }

  &.light-blue {
    .progress-left {
      background-color: $u_light_blue;
      border: solid 1px $u_light_blue;
    }

    .progress-number {
      color: $u_light_blue_surface;
    }
  }

  .progress-number {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-weight: 600;
  }

}
