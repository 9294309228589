button.round {
  background: #fff;
  border-radius: 20px;
  padding: 3px 10px;
  width: 100%;

  &.light-blue {
    border: solid 2px $u_light_blue;
    color: $u_light_blue;
  }

  &.blue {
    border: solid 2px $u_blue;
    color: $u_blue;
  }
}

button {
  box-shadow: none;
  outline: none;
}

.simple {
  border: none;
  color: #fff;
  background: $u_blue_dark;
  padding: 5px 20px;
  border-radius: 20px;
  text-decoration: none;
  display: inline-block;
  &:hover {
    color: #fff
  }
}


.module-button {
  display: block;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  border: solid 6px #009DDD;
  color: #009DDD;
  border-radius: 50px;
  padding: 10px 48px;
  position: relative;
}

.info-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 1.3em;

  svg {
    width: 100%;
  }
}
